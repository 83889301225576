'use client'

import { CdsNotice } from '@circutor/react-ui'
import { useTranslation } from 'react-i18next'
import { ReactNode } from 'react'

export const ErrorCdsNotice = ({
  httpError,
  children,
}: {
  httpError: any
  children?: ReactNode
}) => {
  const { t } = useTranslation()

  const getTitleDescription = () => {
    const statusCodeText = mapStatusCode()
    const title = t(`errors.${statusCodeText}.title`)
    const description = t(`errors.${statusCodeText}.description`)
    return {
      title,
      description,
    }
  }

  const mapStatusCode = () => {
    if (httpError?.status >= 500) return 'connection'

    if (httpError?.status === 403) return 'permissions'

    return 'fallback'
  }

  const { title, description } = getTitleDescription()

  return (
    <CdsNotice
      data-testid='error-cds-notice'
      icon='cds-ph-x-circle-fill'
      text={description}
      title={title}
      feedback='error'
      hero={httpError.status}
    >
      {children}
    </CdsNotice>
  )
}
